import React from 'react';
import {Table} from 'antd';
import Anchor from '../../Widgets/Anchor';

const RoleTable = ({roles, loading, pagination, onPaging}) => {
  const columns = [
    {
      title: '編號',
      render: (_, instance) => (
        <Anchor
          to={`/role/edit/?id=${instance.id}`}>{`#${instance.id}`}</Anchor>
      ),
      width: 50,
      fixed: 'left',
    },
    {
      title: '名稱',
      render: (record) => {
        let value = record.data ? JSON.parse(record.data) : {};
        return (
          <div>
            <div>{record.name}</div>
          </div>
        );
      },
      width: 140,
    },
    {
      title: '角色名稱',
      render: (record) => {
        let value = record.data ? JSON.parse(record.data) : {};
        return (
          <div>
            <div>{record.slug}</div>
          </div>
        );
      },
      width: 140,
    },
    {
      title: '註解',
      render: (record) => {
        let value = record.data ? JSON.parse(record.data) : {};
        return (
          <div>
            <div>{record.comment}</div>
          </div>
        );
      },
      width: 140,
    },
    {
      title: '建立時間',
      render: (_, instance) => ` ${instance.created_at.slice(0, 10)}`,
      width: 100,
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={roles}
      rowKey={(role) => role.id}
      pagination={pagination}
      onChange={onPaging}
    />
  );
};

export default RoleTable;
