import React, {useEffect, useCallback, useState} from 'react';
import RoleTable from './RoleTable';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../../errors';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import styled from 'styled-components';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import {navigate} from 'gatsby';

const appConfig = require('../../data.json');

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function RolePage({...props}) {
  const [roles, setRoles] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useOutlet('loading');
  const [actions] = useOutlet('actions');
  // const [openDialog, setOpenDialog] = useState(false);
  const [filters, setFilters] = useState({
    search_str: '',
    ...PAGINATION_INIT,
  });

  const changeFilters = (obj) => {
    setFilters((prev) => ({...prev, ...PAGINATION_INIT, ...obj}));
  };

  const fetchRoleList = useCallback(async () => {
    let params = {
      search: filters.search_str,
      current: filters.current,
      limit: filters.pageSize,
    };

    setLoading(true);

    try {
      let {
        total_pages,
        current_page,
        count,
        data,
      } = await actions.fetchRoleList(params);
      setRoles(data);
      setTotal(count);
    } catch (err) {
      console.log('err', err);
      Ant.message.error('發生錯誤');
    }
    setLoading(false);
  }, [filters, actions.fetchRoleList]);

  useEffect(() => {
    fetchRoleList();
  }, [fetchRoleList]);

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <h4 className="short">搜尋</h4>
            <Ant.Input.Search
              defaultValue={filters.search_str}
              onSearch={(value) => {
                changeFilters({search_str: value});
              }}
              allowClear
              placeholder="搜尋角色"
            />
          </div>
        </div>
        <div className="divider" />
        <div className="row" style={{margin: 0}}>
          <div style={{fontWeight: '500', marginRight: 5}}>共</div>
          <div style={{fontWeight: '500', marginRight: 5}}>{total}</div>
          <div style={{fontWeight: '500', marginRight: 5}}>筆</div>
          <div style={{flex: 1}} />
          <Button
            type="primary"
            onClick={() => navigate('/role/add/')}
            style={{marginRight: 10}}>
            新增角色
          </Button>
          <Button
            type="primary"
            onClick={fetchRoleList}
            icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}>
            刷新
          </Button>
        </div>
      </Block>

      <RoleTable
        loading={loading}
        roles={roles}
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onPaging={(pagination) =>
          changeFilters({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
      />
      {/* <RoleDialog
        dialogType={openDialog}
        onClose={() => setOpenDialog(false)}
        onUpdate={fetchRoleList}
      /> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
